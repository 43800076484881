var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var WidgetAnalytics = (function (_super) {
        __extends(WidgetAnalytics, _super);
        function WidgetAnalytics() {
            return _super.call(this, {}) || this;
        }
        WidgetAnalytics.prototype.events = function () {
            return {
                'click / .js-widgetTrigger': this.emit
            };
        };
        WidgetAnalytics.prototype.emit = function (e) {
            var $target = $(e.currentTarget);
            console.assert(!!$target.data('category'), 'WidgetAnalytics: `category` is required but undefined');
            console.assert(!!$target.data('action'), 'WidgetAnalytics: `Action` is required but undefined');
            console.assert(!!$target.data('type'), 'WidgetAnalytics: `Target` is required but undefined');
            console.assert(typeof $target.data('category') === 'string', 'Undefined WidgetAnalytics: `category`');
            console.assert(typeof $target.data('action') === 'string', 'Undefined WidgetAnalytics: `Action`');
            Fily.GA.sendEvent($target.data('category'), $target.data('action'), $target.data('type'), 1);
            Fily.Api.Click.count(location.pathname, location.search.slice(1), document.referrer, {
                category: $target.data('category'),
                action: $target.data('action'),
                type: $target.data('type'),
                link_to: $target.attr('href')
            });
        };
        return WidgetAnalytics;
    }(Fily.BaseView));
    Fily.WidgetAnalytics = WidgetAnalytics;
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var ColumnFixView = (function (_super) {
        __extends(ColumnFixView, _super);
        function ColumnFixView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.globalBind();
            _this.init();
            return _this;
        }
        ColumnFixView.prototype.globalBind = function () {
            var $entry = $('.Entry, .side-list li, .Side-list li, .Entry-m');
            $entry.on('click', function () {
                location.href = $(this).find('a:first-child').attr('href');
            });
            var tapToDismiss = $('.js-tapToDismiss');
            tapToDismiss.on('click touchend', function () {
                tapToDismiss.fadeOut();
            });
            tapToDismiss.slideDown(300);
            setTimeout(function () {
                tapToDismiss.slideUp(300);
            }, 5000);
        };
        ColumnFixView.prototype.init = function () {
            var $nav = $('.nav ul');
            var $side = $('.side, .Side').not('.js-relative-ui');
            var $contents = $('.Container-fix');
            var $container = $('.container-inner');
            var $caution = $('.js-side-caution');
            var fixedClass = 'is-fixed';
            var fixedClassTop = 'is-fixed-top';
            var absoluteClass = 'is-absolute';
            if ($nav.get(0)) {
                var navTop = $nav.offset().top;
            }
            if ($side.get(0)) {
                var sideTop = $side.offset().top;
            }
            if ($caution.get(0)) {
                var cautionTop = $caution.offset().top;
            }
            if ($contents.length === 0) {
                return;
            }
            $(window).bind('load scroll', function () {
                var scrollTop = $(this).scrollTop();
                var windowHeight = $(window).height();
                var contentsTop = $('.Container-fix').offset().top;
                if ($side.get(0)) {
                    var containerHeight = $container.outerHeight();
                    var contentsH = $contents.outerHeight();
                    var sideHeight = $side.outerHeight();
                    var coH = contentsTop + sideHeight - windowHeight;
                    var wrapH = contentsTop + containerHeight - windowHeight;
                }
                if ($caution.get(0)) {
                    var containerHeight = $container.outerHeight();
                    var contentsH = $contents.outerHeight();
                    var cautionHeight = $caution.outerHeight();
                    var coH = contentsTop + cautionHeight - windowHeight;
                    var wrapH = contentsTop + containerHeight - windowHeight;
                }
                if ($nav.get(0)) {
                    var navHeight = $nav.outerHeight();
                    var wrapH2 = contentsTop + containerHeight - navHeight - 32;
                    if (scrollTop > navTop - 16) {
                        $nav.addClass(fixedClass);
                        if (scrollTop > wrapH2) {
                            $nav.addClass(absoluteClass);
                        }
                        else {
                            $nav.removeClass(absoluteClass);
                        }
                    }
                    else {
                        $nav.removeClass(fixedClass);
                    }
                }
                if ($side.get(0)) {
                    if (contentsH < sideHeight) {
                    }
                    else if (windowHeight > sideHeight) {
                        var wrapH3 = contentsTop + containerHeight - sideHeight - 16;
                        if (scrollTop > sideTop) {
                            $side.addClass(fixedClassTop);
                            if (scrollTop > wrapH3 - 16) {
                                $side.addClass(absoluteClass);
                            }
                            else {
                                $side.removeClass(absoluteClass);
                            }
                        }
                        else {
                            $side.removeClass(absoluteClass);
                            $side.removeClass(fixedClassTop);
                        }
                    }
                    else {
                        if (contentsH > sideHeight) {
                            if (scrollTop > coH) {
                                $side.addClass(fixedClass);
                                if (scrollTop > wrapH) {
                                    $side.addClass(absoluteClass);
                                }
                                else {
                                    $side.removeClass(absoluteClass);
                                }
                            }
                            else {
                                $side.removeClass(absoluteClass);
                                $side.removeClass(fixedClass);
                            }
                        }
                    }
                }
                if ($caution.get(0)) {
                    if (contentsH < cautionHeight) {
                    }
                    else if (windowHeight > cautionHeight) {
                        var wrapH3 = contentsTop + containerHeight - sideHeight - 16;
                        if (scrollTop > cautionTop) {
                            $caution.addClass(fixedClass);
                            if (scrollTop > wrapH3 - 16) {
                                $caution.addClass(absoluteClass);
                            }
                            else {
                                $caution.removeClass(absoluteClass);
                            }
                        }
                        else {
                            $caution.removeClass(absoluteClass);
                            $caution.removeClass(fixedClass);
                        }
                    }
                    else {
                        if (contentsH > cautionHeight) {
                            if (scrollTop > coH) {
                                $caution.addClass(fixedClass);
                                if (scrollTop > wrapH) {
                                    $caution.addClass(absoluteClass);
                                }
                                else {
                                    $caution.removeClass(absoluteClass);
                                }
                            }
                            else {
                                $caution.removeClass(absoluteClass);
                                $caution.removeClass(fixedClass);
                            }
                        }
                    }
                }
            });
        };
        return ColumnFixView;
    }(Fily.BaseView));
    Fily.ColumnFixView = ColumnFixView;
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var ContentsView = (function (_super) {
        __extends(ContentsView, _super);
        function ContentsView() {
            var _this = _super.call(this, { delegate: false, singleton: true, singletonViewName: 'Fily.ContentsView' }) || this;
            _this.like_inactive_icn_classname = 'icn-like';
            _this.like_active_icn_classname = 'icn-like_done';
            _this.like_active_btn_classname = 'SnsBtn-Like--done';
            _super.prototype.delegateEvent.call(_this);
            _this.init();
            return _this;
        }
        ContentsView.prototype.init = function () {
            this.initLikeStatus();
            $('.js-sns-elements').attr('data-status', 'active');
        };
        ContentsView.prototype.events = function () {
            return {
                'click / .js-like': 'toggleLike',
                'click / .js-tweet': 'shareTwitter',
                'click / .js-shareFb': 'shareFacebook',
                'click / .js-profile-open': function (e) {
                    var $this = $(e.currentTarget);
                    var $bio = $('.js-Profile-userBio');
                    var fullText = $this.data('message-full').replace(/\n/g, '<br>');
                    $bio.html(fullText);
                    $this.hide();
                },
            };
        };
        ContentsView.prototype.toggleLike = function (event) {
            event.stopPropagation();
            event.preventDefault();
            if (!Fily.Myself.isLogin()) {
                location.href = '/users/sign_in';
                return false;
            }
            var $target = $(event.currentTarget);
            var id = $target.data('id');
            var type = $target.data('type');
            var $button = $('.js-like[data-id=' + id + '][data-type=' + type + ']');
            var $icon = $button.children('i');
            var $link = $button.parent('li');
            var flagDefaultClass = this.like_inactive_icn_classname;
            var flagClass = this.like_active_icn_classname;
            var flagButtonClass = this.like_active_btn_classname;
            var activeUI = function () {
                $icon.addClass(flagClass);
                $icon.removeClass(flagDefaultClass);
                $link.addClass(flagButtonClass);
            };
            var unactiveUI = function () {
                $icon.removeClass(flagClass);
                $icon.addClass(flagDefaultClass);
                $link.removeClass(flagButtonClass);
            };
            if ($icon.hasClass(flagClass)) {
                unactiveUI();
                if (type === 'photo') {
                    Fily.Api.Photo.like(id, 'DELETE').fail(activeUI);
                }
                else if (type === 'idea') {
                    Fily.Api.Idea.like(id, false).fail(activeUI);
                }
            }
            else {
                activeUI();
                if (type === 'photo') {
                    Fily.Api.Photo.like(id, 'POST').fail(unactiveUI);
                }
                else if (type === 'idea') {
                    Fily.Api.Idea.like(id).fail(unactiveUI);
                }
            }
            return false;
        };
        ContentsView.prototype.shareTwitter = function (event) {
            var $target = $(event.currentTarget);
            var w = 550;
            var h = 300;
            var pos = Fily.Util.centeredPopUpPosition(w, h);
            Fily.GA.sendSocial('twitter', $target.data('url'));
            return window.open("https://twitter.com/share?url=" + $target.data('url') + "&counturl=" + $target.data('url') + "&text=" + $target.data('title') + "&via=ARINE_JP", 'tweet_window', "width=" + w + ", height=" + h + ", left=" + pos.left + ", top=" + pos.top + ", toolbar=0, location=0, menubar=0, scrollbars=0");
        };
        ContentsView.prototype.shareFacebook = function (event) {
            var $target = $(event.currentTarget);
            var w = 611;
            var h = 368;
            var pos = Fily.Util.centeredPopUpPosition(w, h);
            Fily.GA.sendSocial('facebook', $target.data('url'));
            return window.open("https://www.facebook.com/sharer/sharer.php?u=" + $target.data('url') + "&t=" + $target.data('title'), 'sharefb_window', "width=" + w + ", height=" + h + ", left=" + pos.left + ", top=" + pos.top + ", toolbar=0, location=0, menubar=0, scrollbars=0");
        };
        ContentsView.prototype.initLikeStatus = function () {
            var _this = this;
            var $target = $('.js-like');
            var type = $target.data('type');
            var id = $target.data('id');
            var is_liked = false;
            if (this.finishedRequest()) {
                return;
            }
            if (!Fily.Myself.isLogin()) {
                return;
            }
            if (type === 'photo') {
                Fily.Api.Photo.like(id, 'GET')
                    .done(function (res) {
                    res['is_liked'] ? _this.applyLikeStatus() : _this.applyUnlikeStatus();
                }).fail(function () { return _this.applyUnlikeStatus(); });
            }
            else if (type === 'idea') {
                Fily.Api.Idea.likes(id)
                    .done(function (result) {
                    is_liked = result['is_liked'];
                    is_liked ? _this.applyLikeStatus() : _this.applyUnlikeStatus();
                }).fail(function () { return _this.applyUnlikeStatus(); });
            }
            this.clearRequest();
        };
        ContentsView.prototype.applyLikeStatus = function () {
            var $links = $('.js-like').parents('li');
            var $icon = $('.js-like').children('i');
            $links.addClass(this.like_active_btn_classname + ' FadeIn--extream-fast');
            return $icon.addClass(this.like_active_icn_classname + ' FadeIn--extream-fast');
        };
        ContentsView.prototype.applyUnlikeStatus = function () {
            var $links = $('.js-like').parents('li');
            var $icon = $('.js-like').children('i');
            $links.removeClass(this.like_active_btn_classname);
            return $icon.addClass(this.like_inactive_icn_classname);
        };
        ContentsView.prototype.clearRequest = function () {
            return Fily.ContentsView._initRequested = true;
        };
        ContentsView.prototype.finishedRequest = function () {
            return Fily.ContentsView._initRequested;
        };
        ContentsView._initRequested = false;
        return ContentsView;
    }(Fily.BaseView));
    Fily.ContentsView = ContentsView;
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var FormView = (function (_super) {
        __extends(FormView, _super);
        function FormView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.sendingRequest = false;
            _this.ENTER_KEY = 13;
            _this.$form = $('form.js-form-container');
            _this.$submitButtons = $('.js-form-submit-btn');
            if (_this.$form) {
                _super.prototype.delegateEvent.call(_this);
            }
            return _this;
        }
        FormView.prototype.events = function () {
            return {
                'click / .js-form-submit-btn': 'submitForm',
                'keyup / .js-form-container': 'preventFormSubmitWithEnter',
                'keypress / .js-form-container': 'preventFormSubmitWithEnter'
            };
        };
        FormView.prototype.submitForm = function (e) {
            if (this.sendingRequest) {
                e.preventDefault();
                return false;
            }
            var $temp = $("<input type='hidden'/>");
            $temp.attr('name', $(e.currentTarget).attr('name')).val('');
            this.$form.append($temp);
            this.sendingRequest = true;
            this.$submitButtons.prop('disabled', this.sendingRequest);
            return this.$form.submit();
        };
        FormView.prototype.preventFormSubmitWithEnter = function (e) {
            var keycode = e.keyCode || e.which;
            if (keycode === this.ENTER_KEY && this.excludeTextArea(e.target)) {
                e.preventDefault();
                return false;
            }
            return true;
        };
        FormView.prototype.excludeTextArea = function (target) {
            return $(target)[0] !== $('textarea')[0];
        };
        return FormView;
    }(Fily.BaseView));
    Fily.FormView = FormView;
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PC;
    (function (PC) {
        var MainPhoto = (function (_super) {
            __extends(MainPhoto, _super);
            function MainPhoto() {
                var _this = _super.call(this, { delegate: false }) || this;
                _this.$mainPhoto = $('.js-main-photo');
                _this.$shadows = $('.js-shadow');
                _this.$topShadow = $('.js-topShadow');
                _this.$bottomShadow = $('.js-bottomShadow');
                _this.shadowHeight = Math.round(_this.$mainPhoto.height() - 200 / 375 * _this.$mainPhoto.width());
                if ($('.js-select-top').is(':checked')) {
                    _this.selectTopPosition();
                }
                else if ($('.js-select-center').is(':checked')) {
                    _this.selectCenterPosition();
                }
                else if ($('.js-select-bottom').is(':checked')) {
                    _this.selectBottomPosition();
                }
                _super.prototype.delegateEvent.call(_this);
                return _this;
            }
            MainPhoto.prototype.events = function () {
                return {
                    'click / .js-select-top': 'selectTopPosition',
                    'click / .js-select-center': 'selectCenterPosition',
                    'click / .js-select-bottom': 'selectBottomPosition',
                };
            };
            MainPhoto.prototype.selectTopPosition = function () {
                this.$shadows.height(this.shadowHeight);
                this.$bottomShadow.removeClass('hidden-shadow');
                this.$topShadow.addClass('hidden-shadow');
            };
            MainPhoto.prototype.selectCenterPosition = function () {
                this.$shadows.height(this.shadowHeight / 2);
                this.$topShadow.removeClass('hidden-shadow');
                this.$bottomShadow.removeClass('hidden-shadow');
            };
            MainPhoto.prototype.selectBottomPosition = function () {
                this.$shadows.height(this.shadowHeight);
                this.$topShadow.removeClass('hidden-shadow');
                this.$bottomShadow.addClass('hidden-shadow');
            };
            return MainPhoto;
        }(Fily.BaseView));
        PC.MainPhoto = MainPhoto;
    })(PC = Fily.PC || (Fily.PC = {}));
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PC;
    (function (PC) {
        var Notification = (function (_super) {
            __extends(Notification, _super);
            function Notification() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            Notification.prototype.delegateEvent = function () {
                _super.prototype.delegateEvent.call(this);
                this.delegateScrollEvent(this.activities);
            };
            Notification.prototype.delegateScrollEvent = function (notifications) {
                var _this = this;
                notifications.$list.on('scroll', function (event) {
                    var list = notifications.$list.get(0);
                    if (list.scrollHeight - list.offsetHeight - 200 < list.scrollTop) {
                        _this.loadNotifications(notifications);
                    }
                });
            };
            Notification.prototype.toggleNotification = function () {
                this.$notification.toggleClass('is-active');
                $('html').toggleClass('Modal-is-locked');
                this.$body.focus();
            };
            Notification.prototype.showNotices = function (notifications, noticeTpls) {
                notifications.$list.append(noticeTpls.join(''));
                if (!notifications.notices.length) {
                    notifications.$list.html('<div class="Notifications-empty">通知はありません</div>');
                }
            };
            return Notification;
        }(Fily.Notification));
        PC.Notification = Notification;
    })(PC = Fily.PC || (Fily.PC = {}));
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PageView = (function (_super) {
        __extends(PageView, _super);
        function PageView() {
            var _this = _super.call(this, {}) || this;
            _this.path = location.pathname;
            _this.query = location.search.slice(1);
            _this.referer = document.referrer;
            _this.pageViewTag = {};
            _this.pageViewTag['id'] = $('.js-pv-tag').data('id');
            _this.pageViewTag['type'] = $('.js-pv-tag').data('type');
            new Fily.PageviewCounter(_this.path, _this.query, _this.referer, _this.pageViewTag);
            return _this;
        }
        return PageView;
    }(Fily.BaseView));
    Fily.PageView = PageView;
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PC;
    (function (PC) {
        var SignupView = (function (_super) {
            __extends(SignupView, _super);
            function SignupView() {
                return _super.call(this, {}) || this;
            }
            SignupView.prototype.events = function () {
                return {
                    'click / #js-loginFacebook': function (e) {
                        e.preventDefault();
                        var $form = $('#js-loginFacebookForm');
                        if ($('#js-mailmagazineCheck').prop('checked')) {
                            $form.find('[name^="receive_newsletter"]').val('true');
                        }
                        $form.submit();
                        return false;
                    },
                    'click / #js-signupButton': function (e) {
                        var $button = $(e.currentTarget);
                        setTimeout(function () {
                            $button.prop('disabled', true);
                            setTimeout(function () {
                                $button.prop('disabled', false);
                            }, 1000);
                        });
                    },
                    'focus / .js-hintInput': function (e) {
                        $(e.currentTarget).siblings('.js-hintView').addClass('is-active');
                    }
                };
            };
            return SignupView;
        }(Fily.BaseView));
        PC.SignupView = SignupView;
    })(PC = Fily.PC || (Fily.PC = {}));
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PC;
    (function (PC) {
        var StickyHeaderView = (function (_super) {
            __extends(StickyHeaderView, _super);
            function StickyHeaderView() {
                var _this = _super.call(this, { delegate: true }) || this;
                _this.fixed = false;
                _this.nav = document.querySelector('.js-navigation');
                _this.stickyNav = document.querySelector('.js-stick-navigation');
                _this.stickPoint = _this.nav.offsetTop + _this.nav.offsetHeight;
                _this.renderHeader();
                window.onscroll = function () {
                    _this.renderHeader();
                };
                return _this;
            }
            StickyHeaderView.prototype.renderHeader = function () {
                var distance = this.stickPoint - window.pageYOffset;
                var yOffset = window.pageYOffset;
                if (distance < 0 && !this.fixed) {
                    this.affixMinimizedHeader();
                }
                else if (this.fixed && yOffset < this.stickPoint) {
                    this.showEntireHeader();
                }
            };
            StickyHeaderView.prototype.showEntireHeader = function () {
                $(this.stickyNav).removeClass('Header-navigation--affixed');
                this.fixed = false;
            };
            StickyHeaderView.prototype.affixMinimizedHeader = function () {
                $(this.stickyNav).addClass('Header-navigation--affixed');
                this.fixed = true;
            };
            return StickyHeaderView;
        }(Fily.BaseView));
        PC.StickyHeaderView = StickyHeaderView;
    })(PC = Fily.PC || (Fily.PC = {}));
})(Fily || (Fily = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var UserView = (function (_super) {
        __extends(UserView, _super);
        function UserView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _super.prototype.delegateEvent.call(_this);
            return _this;
        }
        UserView.prototype.events = function () {
            return {
                'click / .js-open': function (e) {
                    var $this = $(e.currentTarget);
                    var $bio = $('.js-Profile-userBio');
                    var fullText = $this.data('message-full').replace(/\n/g, '<br>');
                    var excerptText = $this.data('message-excerpt').replace(/\n/g, '<br>');
                    var isExpanded = $bio.attr('aria-expanded') === 'true';
                    if (isExpanded) {
                        $bio.attr('aria-expanded', String(!isExpanded));
                        setTimeout(function () {
                            $bio.html(excerptText);
                        }, 250);
                    }
                    else {
                        $bio.attr('aria-expanded', String(!isExpanded)).html(fullText);
                    }
                },
            };
        };
        return UserView;
    }(Fily.BaseView));
    Fily.UserView = UserView;
})(Fily || (Fily = {}));
